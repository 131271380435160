.career-container {
    display: flex;
    flex-flow: column;
    align-items: left;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
    padding:0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .career-container-heading {
    margin-bottom: 20px;
    border-left:10px solid #1f57f4;
    padding-left: 15px;
  }
  
  .career-container-details {
    text-align: justify;
    padding: 20px;
    border: 1px solid #DEDEDE;
  }

  .career-container-details-list {
    padding: 20px;
  }