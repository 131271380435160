* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size:16px;
}

p {
  padding: 5px 0px;
}

.home,
.careerhistory,
.projects,
.contact {
  display: flex;
  height: 25vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  background-image: url('images/banner-home.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.careerhistory {
  background-image: url('images/banner-career.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.projects {
  background-image: url('images/banner-projects.webp');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact {
  background-image: url('images/banner-contact.webp'); 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.section-container {
  display: flex;
  flex-flow: column;
  align-items: left;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  padding:0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.section-container-heading {
  font-weight: bold;
  margin-bottom: 20px;
}

.section-container-details {
  text-align: justify;
  padding: 20px;
  border: 1px solid #DEDEDE;
}